import React from "react";
import EditableMeasurement from "../EditableMeasurement/EditableMeasurement";
import "./Rulers.css";

const Rulers = ({
  originalDimensions,
  dimensions,
  units,
  onFormSubmit,
  scale,
}) => {
  const axes = ["length", "height", "width"];
  const distanceFromBox = 30;
  const styles = {
    length: {
      width: dimensions.length + "px",
      transformOrigin: "left center",
      transform: `translateX(${
        originalDimensions.width + (dimensions.width - originalDimensions.width)
      }px) translateZ(${
        originalDimensions.length / 2
      }px) rotateY(90deg) translateY(${
        dimensions.height
      }px) translateZ(${distanceFromBox}px)`,
    },
    width: {
      width: dimensions.width + "px",
      transform: `translateZ(${originalDimensions.length / 2}px) translateY(${
        dimensions.height
      }px) translateZ(${distanceFromBox}px)`,
    },
    height: {
      height: dimensions.height + "px",
      transform: `translateZ(${
        originalDimensions.width / 2
      }px) translateX(${-dimensions.width}px) translateX(${-distanceFromBox}px)`,
    },
  };

  return (
    <>
      {axes.map((axis, index) => {
        const capitalizedAxis = axis[0].toUpperCase() + axis.slice(1);
        return (
          <div
            key={index}
            className={`ruler ruler${capitalizedAxis}`}
            style={styles[axis]}
          >
            <EditableMeasurement
              dimensions={dimensions}
              axis={axis}
              units={units}
              onFormSubmit={onFormSubmit}
              scale={scale}
            />
          </div>
        );
      })}
    </>
  );
};

export default Rulers;
