import React from "react";
import "./EditableMeasurement.css";

const EditableMeasurement = ({
  dimensions,
  axis,
  units,
  onFormSubmit,
  scale,
}) => {
  const convertPxToUnits = (value, unit) => {
    const conversionFactor = { mm: 1, cm: 10, in: 25.4 };
    const result = value / conversionFactor[unit];
    return result;
  };

  const convertUnitsToPx = (value, unit) => {
    const conversionFactor = { mm: 1, cm: 10, in: 25.4 };
    const result = value * conversionFactor[unit];
    return result;
  };

  const [isEditing, setIsEditing] = React.useState(false);
  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const [convertedDimensions, setConvertedDimensions] =
    React.useState(dimensions);
  const [userEnteredDimensions, setUserEnteredDimensions] = React.useState({
    width: convertPxToUnits(dimensions.width, units),
    length: convertPxToUnits(dimensions.length, units),
    height: convertPxToUnits(dimensions.height, units),
  });
  const [initialValue, setInitialValue] = React.useState(null);

  const handleClickToEdit = () => {
    toggleEditing();
    const value = Number(
      convertPxToUnits(convertedDimensions[axis], units).toFixed(2)
    );
    setInitialValue(value);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const handleInputChange = (event, axis) => {
    setInitialValue(null);
    const newDimensions = { ...dimensions };
    const newValue = convertUnitsToPx(Number(event.target.value), units);
    newDimensions[axis] = newValue;
    setConvertedDimensions(newDimensions);
    const userEnteredValues = { ...userEnteredDimensions };
    userEnteredValues[axis] = event.target.value;
    setUserEnteredDimensions(userEnteredValues);
  };

  React.useEffect(() => {
    setConvertedDimensions(dimensions);
  }, [dimensions]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setIsEditing(false);
    let value;
    if (units === "in") {
      value = Math.min(Math.max(convertedDimensions[axis], 25.4), 508);
    } else {
      value = Math.min(Math.max(convertedDimensions[axis], 25), 500);
    }
    onFormSubmit(event, axis, value);
  };

  const capitalizedAxis = axis[0].toUpperCase() + axis.slice(1);

  const UseInputClickListener = (ref) => {
    React.useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleFormSubmit(event);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  const inputRef = React.useRef(null);
  UseInputClickListener(inputRef);

  return (
    <>
      {isEditing ? (
        <div className="scalingWrap">
          <form
            className={`boxMeasurement box${capitalizedAxis}`}
            onSubmit={handleFormSubmit}
            style={{ transform: `scale(${1 / scale})` }}
          >
            <label htmlFor={`box${capitalizedAxis}`}></label>
            <input
              className="editing"
              ref={inputRef}
              title={`box${capitalizedAxis}`}
              type="number"
              step="any"
              value={initialValue || userEnteredDimensions[axis]}
              autoFocus
              onChange={(event) => handleInputChange(event, axis)}
              onFocus={handleFocus}
            ></input>
            <span className="units">{units}</span>
          </form>
        </div>
      ) : (
        <div className={`scalingWrap wrap${capitalizedAxis}`}>
          <div
            className={`boxMeasurement box${capitalizedAxis}`}
            style={{ transform: `scale(${1 / scale})` }}
            onClick={handleClickToEdit}
          >
            <span className="boxMeasurementOutline">
              {units === "in"
                ? convertPxToUnits(dimensions[axis], units).toFixed(2)
                : Number(convertPxToUnits(dimensions[axis], units).toFixed(2))}
            </span>
            {units}
          </div>
        </div>
      )}
    </>
  );
};

export default EditableMeasurement;
