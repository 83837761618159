import React from "react";
import ResizableBox from "../ResizableBox/ResizableBox";
import "./Calculator.css";

const Calculator = () => {
  const originalBoxDimensions = {
    width: 203.2,
    height: 203.2,
    length: 203.2,
    rotateX: -30,
    rotateY: -45,
  };
  const [boxDimensions, setBoxDimensions] = React.useState(
    originalBoxDimensions
  );
  const [paperDimensions, setPaperDimensions] = React.useState({
    width: null,
    length: null,
  });
  const [units, setUnits] = React.useState("in");
  const [overlapInput, setOverlapInput] = React.useState({ value: 1, units });
  const [paperOverlap, setPaperOverlap] = React.useState({
    inputValue: 0,
    width: 0,
    length: 0,
    displayValue: 0,
  });
  const [shouldRound, setShouldRound] = React.useState(false);

  const convertPxToUnits = (value, unit) => {
    const conversionFactor = { mm: 1, cm: 10, in: 25.4 };
    const result = value / conversionFactor[unit];
    return result;
  };

  const convertUnitsToPx = (value, unit) => {
    const conversionFactor = { mm: 1, cm: 10, in: 25.4 };
    const result = value * conversionFactor[unit];
    return result;
  };

  const roundMeasurement = React.useCallback(
    (valueInPixels, units) => {
      if (!shouldRound) {
        return valueInPixels;
      }

      if (units === "in") {
        valueInPixels =
          Math.round(valueInPixels * (1 / (0.25 * 25.4))) / (1 / (0.25 * 25.4));
      } else if (units === "cm") {
        valueInPixels =
          Math.round(valueInPixels * (1 / (0.1 * 10))) / (1 / (0.1 * 10));
      } else if (units === "mm") {
        valueInPixels = Math.round(valueInPixels);
      }
      return valueInPixels;
    },
    [shouldRound]
  );

  React.useEffect(() => {
    let width = boxDimensions.width + boxDimensions.height;
    let overlapWidth;
    let length = boxDimensions.height * 2 + boxDimensions.length * 2;
    let overlapLength;

    width = roundMeasurement(width, units);
    length = roundMeasurement(length, units);

    if (overlapInput.units === "percent") {
      overlapWidth = 2 * width * (overlapInput.value / 100);
      overlapLength = 2 * length * (overlapInput.value / 100);
      overlapWidth = roundMeasurement(overlapWidth, units);
      overlapLength = roundMeasurement(overlapLength, units);
      overlapWidth = Number(convertPxToUnits(overlapWidth, units).toFixed(2));
      overlapLength = Number(convertPxToUnits(overlapLength, units).toFixed(2));
    } else {
      let overlapPx = 2 * convertUnitsToPx(overlapInput.value, units);
      overlapPx = roundMeasurement(overlapPx, units);
      overlapWidth = Number(convertPxToUnits(overlapPx, units).toFixed(2));
      overlapLength = overlapWidth;
    }

    setPaperOverlap((prev) => {
      return {
        ...prev,
        width: overlapWidth,
        length: overlapLength,
      };
    });

    width = Number(convertPxToUnits(width, units).toFixed(2));
    length = Number(convertPxToUnits(length, units).toFixed(2));
    setPaperDimensions({ width, length });
  }, [boxDimensions, overlapInput, units, shouldRound, roundMeasurement]);

  const handleInputChange = (event) => {
    setOverlapInput({
      ...overlapInput,
      value: event.target.value,
    });
  };

  return (
    <div className="calculator">
      <div className="boxAndForm">
        <div className="boxFrame">
          <ResizableBox
            units={units}
            dimensions={boxDimensions}
            setDimensions={setBoxDimensions}
            originalDimensions={originalBoxDimensions}
          />
        </div>
        <form
          onSubmit={(event) => event.preventDefault()}
          className="paperForm"
        >
          <div className="unitsInput">
            <label htmlFor="units">Units </label>
            <select
              name="units"
              id="units"
              value={units}
              onChange={(event) => setUnits(event.target.value)}
            >
              <option value="cm">cm</option>
              <option value="in">in</option>
              <option value="mm">mm</option>
            </select>
          </div>
          <div className="overlapInput">
            <label htmlFor="overlap">Overlap </label>
            <div className="combinedInput">
              <input
                id="overlap"
                type="number"
                step="any"
                value={overlapInput.value}
                onChange={handleInputChange}
              ></input>
              <label htmlFor="overlapUnits"></label>
              <select
                name="overlapUnits"
                id="overlapUnits"
                value={overlapInput.units}
                onChange={(event) =>
                  setOverlapInput({
                    ...overlapInput,
                    units: event.target.value,
                  })
                }
              >
                <option value={units}>{units}</option>
                <option value="percent">%</option>
              </select>
            </div>
            {overlapInput.units === "percent" ? (
              <div className="percentInfo">
                <dl>
                  <dt>Width</dt>
                  <dd>{`${paperOverlap.width} ${units}`}</dd>
                  <dt>Length</dt>
                  <dd>{`${paperOverlap.length} ${units}`}</dd>
                </dl>
              </div>
            ) : null}
          </div>
        </form>
      </div>
      <div className="results">
        <h2>Wrapping Paper Size</h2>
        <div className="roundingToggle">
          <label htmlFor="rounding">Round dimensions</label>
          <input
            id="rounding"
            type="checkbox"
            checked={!!shouldRound}
            onChange={() => setShouldRound(!shouldRound)}
          ></input>
        </div>
        <dl>
          <dt>
            <h3>Width: </h3>
          </dt>
          <dd>{`${Number(
            (
              Number(paperDimensions.width) + Number(paperOverlap.width)
            ).toFixed(2)
          )} ${units}`}</dd>
          {Number(overlapInput.value) === 0 ? null : (
            <dd className="overlapBreakdown">{`(${paperDimensions.width} + ${paperOverlap.width}) ${units}`}</dd>
          )}
          <br></br>
          <dt>
            <h3>Length: </h3>
          </dt>
          <dd>{`${Number(
            (
              Number(paperDimensions.length) + Number(paperOverlap.length)
            ).toFixed(2)
          )} ${units}`}</dd>
          {Number(overlapInput.value) === 0 ? null : (
            <dd className="overlapBreakdown">{`(${paperDimensions.length} + ${paperOverlap.length}) ${units}`}</dd>
          )}
        </dl>
      </div>
    </div>
  );
};

export default Calculator;
