import React from "react";
import "./ResizableBox.css";
import Icon from "../Icon/Icon";
import PerceivedDimensions from "./PerceivedDimensions/PerceivedDimensions";
import Rulers from "./Rulers/Rulers";

const ResizableBox = ({
  units,
  dimensions,
  setDimensions,
  originalDimensions,
}) => {
  const [change, setChange] = React.useState({
    width: 0,
    height: 0,
    length: 0,
  });

  const [perceivedDimensions, setPerceivedDimensions] = React.useState({
    width: null,
    height: null,
  });
  React.useEffect(() => {
    const vertexToLeftEdge =
      dimensions.width * Math.sin((2 * Math.PI * 45) / 360);
    const vertexToRightEdge =
      dimensions.length * Math.sin((2 * Math.PI * 45) / 360);
    const width = vertexToLeftEdge + vertexToRightEdge;

    const hypotenuse = Math.sqrt(
      Math.pow(dimensions.width, 2) + Math.pow(dimensions.length, 2)
    );
    const angleOfHypotenuse = Math.acos(dimensions.width / hypotenuse);
    const apparentHypotenuse =
      hypotenuse * Math.cos((2 * Math.PI * 45) / 360 - angleOfHypotenuse);
    const apparentHeightAboveVertex =
      apparentHypotenuse * Math.tan((2 * Math.PI * 30) / 360);
    const apparentTotalHeight =
      (apparentHeightAboveVertex + dimensions.height) *
      Math.sin((2 * Math.PI * 60) / 360);
    const height = apparentTotalHeight;

    setPerceivedDimensions({ width, height });
  }, [dimensions]);

  const [scale, setScale] = React.useState(1);
  React.useEffect(() => {
    const initialWidth =
      (originalDimensions.width + originalDimensions.height) *
      Math.sin(Math.PI / 4); // As calculated in PerceivedDimensions
    const initialHeight =
      (Math.sqrt(
        Math.pow(originalDimensions.width, 2) +
          Math.pow(originalDimensions.length, 2)
      ) *
        Math.cos(
          Math.PI / 4 -
            Math.acos(
              originalDimensions.width /
                Math.sqrt(
                  Math.pow(originalDimensions.width, 2) +
                    Math.pow(originalDimensions.length, 2)
                )
            )
        ) *
        Math.tan(Math.PI / 6) +
        originalDimensions.height) *
      Math.sin(Math.PI / 3); // As calculated in PerceivedDimensions
    const width = perceivedDimensions.width || initialWidth;
    const widthScale = initialWidth / width;
    const height = perceivedDimensions.height || initialHeight;
    const heightScale = initialHeight / height;
    const overallScale = Math.min(widthScale, heightScale);
    const scaleWhenLarger = Math.min(1, overallScale);
    setScale(scaleWhenLarger);
  }, [perceivedDimensions, originalDimensions]);

  const handleFormSubmit = (event, axis, value) => {
    event.preventDefault();
    const newDimensions = { ...dimensions };
    newDimensions[axis] = value;
    setDimensions(newDimensions);
    const difference = { ...change };
    difference[axis] += newDimensions[axis] - dimensions[axis];
    setChange(difference);
  };

  const dragProps = React.useRef();
  const [isDragging, setIsDragging] = React.useState(false);
  const [dragDetails, setDragDetails] = React.useState({
    face: null,
    direction: null,
  });

  const initializeDrag = (event) => {
    setIsDragging(true);
    const { clientX, clientY } = event;
    const dragPositionX = clientX || Math.round(event.targetTouches[0].clientX);
    const dragPositionY = clientY || Math.round(event.targetTouches[0].clientY);
    dragProps.current = {
      dragStartX: dragPositionX,
      dragStartY: dragPositionY,
      prevX: dragPositionX,
      prevY: dragPositionY,
    };

    const face = event.target.className;
    if (typeof face === "string") {
      if (face.includes("left")) {
        dragProps.current.face = "left";
      } else if (face.includes("right")) {
        dragProps.current.face = "right";
      } else if (face.includes("top")) {
        dragProps.current.face = "top";
      }
    } else {
      return;
    }

    if (event.type === "mousedown") {
      window.addEventListener("mousemove", startDragging);
      window.addEventListener("mouseup", stopDragging);
    } else if (event.type === "touchstart") {
      window.addEventListener("touchmove", startDragging);
      window.addEventListener("touchend", stopDragging);
    }
  };

  const startDragging = (event) => {
    const { clientX, clientY, targetTouches } = event;
    const dragPositionX = targetTouches
      ? Math.round(targetTouches[0].clientX)
      : clientX;
    const dragPositionY = targetTouches
      ? Math.round(targetTouches[0].clientY)
      : clientY;

    const dragDistanceX = dragPositionX - dragProps.current.dragStartX;
    const dragDistanceY = dragPositionY - dragProps.current.dragStartY;

    const face = dragProps.current.face;
    let axis;
    let dragDistance = 0;
    const newDimensions = { ...dimensions };

    document.body.classList.add("dragging");

    if (face === "left") {
      axis = "length";
      dragDistance = -Math.round(
        dragDistanceX / Math.cos((2 * Math.PI * 30) / 360)
      );
      const isDraggingRight = dragPositionX > dragProps.current.prevX;
      const isDraggingLeft = dragPositionX < dragProps.current.prevX;
      if (isDraggingRight) {
        setDragDetails({ face, direction: "upToRight" });
      } else if (isDraggingLeft) {
        setDragDetails({ face, direction: "downToLeft" });
      }
    } else if (face === "right") {
      axis = "width";
      dragDistance = Math.round(
        dragDistanceX / Math.cos((2 * Math.PI * 30) / 360)
      );
      const isDraggingRight = dragPositionX > dragProps.current.prevX;
      const isDraggingLeft = dragPositionX < dragProps.current.prevX;
      if (isDraggingLeft) {
        setDragDetails({ face, direction: "upToLeft" });
      } else if (isDraggingRight) {
        setDragDetails({ face, direction: "downToRight" });
      }
    } else if (face === "top") {
      axis = "height";
      dragDistance = -dragDistanceY;
      const isDraggingUp = dragPositionY < dragProps.current.prevY;
      const isDraggingDown = dragPositionY > dragProps.current.prevY;
      if (isDraggingUp) {
        setDragDetails({ face, direction: "up" });
      } else if (isDraggingDown) {
        setDragDetails({ face, direction: "down" });
      }
    }

    if (dimensions[axis] % 1 !== 0) {
      newDimensions[axis] =
        Number(Number(dimensions[axis]).toFixed(0)) + dragDistance;
    } else {
      newDimensions[axis] = dimensions[axis] + dragDistance;
    }

    if (units === "in") {
      newDimensions[axis] =
        Math.round(newDimensions[axis] * (1 / (0.25 * 25.4))) /
        (1 / (0.25 * 25.4));
      newDimensions[axis] = Math.min(Math.max(newDimensions[axis], 25.4), 508);
    } else if (units === "cm") {
      newDimensions[axis] =
        Math.round(newDimensions[axis] * (1 / 10)) / (1 / 10);
      newDimensions[axis] = Math.min(Math.max(newDimensions[axis], 25), 500);
    } else {
      newDimensions[axis] = Math.min(Math.max(newDimensions[axis], 25), 500);
    }

    dragProps.current.prevX = dragPositionX;
    dragProps.current.prevY = dragPositionY;
    setDimensions(newDimensions);
    const difference = { ...change };
    difference[axis] += newDimensions[axis] - dimensions[axis];
    setChange(difference);
  };

  const stopDragging = ({ type }) => {
    document.body.classList.remove("dragging");
    setIsDragging(false);
    setDragDetails({ face: null, axis: null, direction: null });
    if (type === "mouseup") {
      window.removeEventListener("mousemove", startDragging);
      window.removeEventListener("mouseup", stopDragging);
    } else if (type === "touchend") {
      window.removeEventListener("touchmove", startDragging);
      window.removeEventListener("touchend", stopDragging);
    }
  };

  const faces = ["left", "top", "right"];
  const styles = {
    left: {
      width: dimensions.width + "px",
      height: dimensions.height + "px",
      transform: `translateZ(${originalDimensions.length / 2}px)`,
    },
    right: {
      width: dimensions.length + "px",
      height: dimensions.height + "px",
      transformOrigin: "left center",
      transform: `translateX(${
        originalDimensions.width + (dimensions.width - originalDimensions.width)
      }px) translateZ(${originalDimensions.length / 2}px) rotateY(90deg)`,
    },
    top: {
      width: dimensions.width + "px",
      height: dimensions.length + "px",
      transformOrigin: "top center",
      transform: `translateZ(${
        originalDimensions.height / 2
      }px) rotateX(-90deg)`,
    },
  };

  return (
    <div className="scalingContainer">
      <div
        className="boxContainer"
        style={{
          transform: `scale(${scale})`,
          width: `${(99 * 1) / scale}%`,
          height: `${perceivedDimensions.height}px`,
        }}
      >
        <PerceivedDimensions
          perceivedDimensions={perceivedDimensions}
          scale={scale}
          visible={false}
        />
        <div
          className="box"
          style={{
            width: originalDimensions.width + change.width,
            height: originalDimensions.height + change.height,
            transform: `rotateX(-30deg) rotateY(-45deg) translateZ(${
              change.length / 2
            }px)`,
          }}
        >
          {faces.map((face, index) => {
            return (
              <div
                key={index}
                className={`face noSelect ${face}`}
                style={styles[face]}
                onMouseDown={initializeDrag}
                onTouchStart={initializeDrag}
              >
                <DragIndicators
                  face={face}
                  isDragging={isDragging}
                  dragDetails={dragDetails}
                  scale={scale}
                />
              </div>
            );
          })}
          <Rulers
            originalDimensions={originalDimensions}
            dimensions={dimensions}
            onFormSubmit={handleFormSubmit}
            units={units}
            scale={scale}
          />
          <div
            className="wrappingPaper noSelect"
            style={{
              borderWidth: `${3 / scale}px`,
              width: `${dimensions.width + dimensions.height}px`,
              height: `${dimensions.height * 2 + dimensions.length * 2}px`,
              transform: `rotateX(-90deg) translateY(${
                -(2 * dimensions.height + dimensions.length) / 2 -
                originalDimensions.length / 2
              }px) translateX(${-dimensions.height / 2}px) translateZ(${
                dimensions.height
              }px)`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const DragIndicators = ({ face, isDragging, dragDetails, scale }) => {
  const direction = dragDetails.direction || null;
  const capitalizedDirection = direction
    ? direction[0].toUpperCase() + direction.slice(1)
    : null;
  return (
    <>
      {isDragging && dragDetails.face === face ? (
        <>
          <Icon
            iconName="arrow"
            className={`arrow arrow${capitalizedDirection}`}
            style={{ width: `${50 / scale}px` }}
          />
        </>
      ) : null}
    </>
  );
};

export default ResizableBox;
