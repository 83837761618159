import "./Slides.css";

const Slide1 = () => {
  return (
    <div className="instruction">
      <div className="transformContainer">
        <div className="boxFace step1 leftFace"></div>
        <div className="boxFace step1 rightFace"></div>
        <div className="boxFace step1 topFace"></div>
        <div className="paper step1"></div>
      </div>
    </div>
  );
};

export default Slide1;
