import "./Slides.css";

const Slide5 = () => {
  return (
    <div className="instruction">
      <div className="transformContainer">
        <div className="paper step5 side"></div>
        <div className="paper step5 nearFoldedSide"></div>
        <div className="paper step5 farFoldedSide"></div>
        <div className="paper step5 nearFoldedTop"></div>
        <div className="paper step5 farFoldedTop"></div>
        {[...Array(2)].map((item, index) => {
          return (
            <svg
              key={index}
              className={`svgFold step5 fold${index + 1}`}
              viewBox="0 0 150 45"
              xmlns="http://www.w3.org/2000/svg"
            >
              <polygon points="4,4 146,4 101,41 49,41" />
            </svg>
          );
        })}
      </div>
    </div>
  );
};

export default Slide5;
