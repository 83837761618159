import React from "react";
import "./PerceivedDimensions.css";

const PerceivedDimensions = ({
  perceivedDimensions,
  scale,
  visible = false,
}) => {
  return (
    <>
      {visible ? (
        <div
          className="perceivedDimensionsContainer"
          style={{
            width: `${perceivedDimensions.width}px`,
            height: `${perceivedDimensions.height}px`,
          }}
        >
          <div
            className="noSelect perceivedWidthContainer"
            style={{
              width: `${perceivedDimensions.width}px`,
              height: `${perceivedDimensions.height}px`,
              borderLeft: `${1 / scale}px solid green`,
              borderRight: `${1 / scale}px solid green`,
            }}
          >
            <div
              className="perceivedWidth"
              style={{
                width: `${perceivedDimensions.width}px`,
                borderTop: `${1 / scale}px solid green`,
              }}
            ></div>
          </div>
          <div
            className="noSelect perceivedHeightContainer"
            style={{
              width: `${perceivedDimensions.width}px`,
              borderTop: `${1 / scale}px solid blue`,
              borderBottom: `${1 / scale}px solid blue`,
            }}
          >
            <div
              className="perceivedHeight"
              style={{
                height: `${perceivedDimensions.height}px`,
                borderLeft: `${1 / scale}px solid blue`,
              }}
            ></div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default PerceivedDimensions;
