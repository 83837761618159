import "./Slides.css";

const Slide3 = () => {
  return (
    <div className="instruction">
      <div className="transformContainer">
        <div className="boxFace step3 rightFace"></div>
        <div className="paper step3 bottom"></div>
        <div className="paper step3 nearSide"></div>
        <div className="paper step3 farSide"></div>
        <div className="paper step3 nearTop"></div>
        <div className="paper step3 farTop"></div>
      </div>
    </div>
  );
};

export default Slide3;
