import "./Slides.css";

const Slide2 = () => {
  return (
    <div className="instruction">
      <div className="transformContainer">
        <div className="boxFace step2 rightFace"></div>
        <div className="boxFace step2 topFace"></div>
        <div className="paper step2 bottom"></div>
        <div className="paper step2 side"></div>
        <div className="paper step2 nearTop"></div>
      </div>
    </div>
  );
};

export default Slide2;
