import "./App.css";
import Calculator from "./components/Calculator/Calculator";
import SlideReel from "./components/SlideReel/SlideReel";

const App = () => {
  return (
    <div className="App">
      <header>
        <h1>WRAPPING PAPER CALCULATOR</h1>
      </header>
      <ul>
        <li>
          This calculator was created to help you{" "}
          <span className="italic">estimate</span> how much paper you will need
          to wrap a gift box.
        </li>
        <li>
          Try dragging the sides of the box on screen to match your gift box as
          closely as possible. You can click on the numbers themselves to
          manually enter the dimensions.
        </li>
        <li>
          You can also change the overlap of the paper to make sure that ends
          always meet.
        </li>
      </ul>
      <p className="accentParagraph">Good luck wrapping!</p>
      <Calculator />
      <SlideReel />
    </div>
  );
};

export default App;
